import http from '~/http-common'
import { type StandardResponse } from '~~/types/httpResponseData'
import { type Router } from 'vue-router'
import { logout } from './logout'

export const httpInterceptors = (router: Router) => {
  http.interceptors.response.use(async (response: StandardResponse) => {
    if (response.data) {
      if ((response.data.code == 500 && response.data.msg == 'システムエラー　管理者に連絡してください')
        || (response.data.code == -1 && response.data.msg == '生徒情報データがありません。')) {
        // 想定外エラー（セッション切れ等）
        // 強制ログアウト
        await logout(router)
        return Promise.reject(new Error('Unexpected error'))
      }
    }
    // 成功時の処理
    return response
  },
  (error: Error) => {
    return Promise.reject(error) // エラーをそのまま返却
  })
}
